<template>
  <div  class="px-15px px-lg-25px">
    
    <div class="col-lg-12">
      <div class="card">
        <div class="card-header">
            <h5 class="card-title">
                {{ detailData.name }}
            </h5>
        </div>

        <div class="card-body">
          <ul class="list-group list-group-flush">
            <li class="list-group-item px-0">
              <div class="media mb-2">
                <img class="avatar avatar-xs mr-3" :src="headerUrl">
                <div class="media-body">
                  <h6 class="mb-0 fw-600">
                    {{detailData.username}}
                  </h6>
                  <p class="opacity-50">{{ detailData.date }}</p>
                </div>
              </div>
              <p>
                {{ detailData.question }}
              </p>
            </li>
          </ul>
          <form >
            <div class="row">
              <div class="col-md-12">
                <textarea class="form-control height-auto" rows="4" name="reply" :placeholder="$t('qing-shu-ru-nin-de-hui-fu')" required></textarea>
              </div>
            </div>
            <br>
            <div class="text-right">
              <button type="submit" class="btn btn-info">{{ $t('fa-song') }}</button>
            </div>
          </form>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import pageBar from '@/components/page'
export default {
  components: {
    pageBar
  },
  data() {
    return {
      hasPackage: true,
      headerUrl: require('../../../assets/imgs/avatar-place.png'),
      detailData: {}
    }
  }
}
</script>